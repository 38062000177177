<template>
  <div class="login-wrapper">
    <header class="header">
      <img
        :src="require('@/assets/pics/logo_white.png')"
        style="width: 130px"
      />
    </header>
    <div
      class="login"
      :class="{ user: role === 'user', admin: role !== 'user' }"
    >
      <main class="login-box">
        <section class="title">
          {{ $t('serviceProvider.logIn.logIn') }}
        </section>
        <!-- <section v-if="role !== 'user'" class="action">
          <ButtonElement
            v-if="role === 'admin'"
            @click="$router.push({ name: 'Login', params: { role: 'staff' } })"
            link
          >
            {{ $t('serviceProvider.logIn.switchToStaffLogin') }}
          </ButtonElement>
          <ButtonElement
            v-else
            @click="$router.push({ name: 'Login', params: { role: 'admin' } })"
            link
          >
            {{ $t('serviceProvider.logIn.switchToAdminLogin') }}
          </ButtonElement>
        </section> -->

        <div v-if="$route.query.registered" class="alert alert-success">
          Sie wurden erfolgreich registriert. Sie können sich nun anmelden.
        </div>

        <div v-else-if="$route.query.passwordReset" class="alert alert-info">
          Wir haben Ihnen den Link zum Zurücksetzen des Passworts per E-Mail
          zugesandt!
        </div>

        <div v-else-if="$route.query.passwordReseted" class="alert alert-info">
          Ihr Passwort wurde zurückgesetzt!
        </div>

        <ValidationObserver
          tag="form"
          @submit.prevent="onSubmit(role)"
          ref="loginForm"
          v-slot="{ invalid }"
        >
          <section class="form-control-custom">
            <div class="input-email">
              <InputElement
                v-model="email"
                validation-rules="required|email"
                validation-name=""
                placeholder="Email"
                type="email"
              />
            </div>
            <div class="input-password">
              <InputElement
                class="password"
                v-model="password"
                validation-rules="required|min:8"
                :type="passwordVisibility ? 'text' : 'password'"
                :placeholder="$t('serviceProvider.signUp.password')"
                validation-name=""
              />
              <ButtonElement
                @click="passwordVisibility = !passwordVisibility"
                link
              >
                <EyeIcon class="eye-icon" />
              </ButtonElement>
            </div>
          </section>
          <section class="password-actions">
            <div class="remember-me">
              <span>
                <CheckboxElement v-model="rememberMe" />
              </span>
              <span>
                {{ $t('serviceProvider.logIn.rememberMe') }}
              </span>
            </div>
            <div class="forgot-password">
              <ButtonElement
                :anchor="{ name: 'ForgotPassword', query: { role } }"
                link
              >
                {{ $t('serviceProvider.logIn.forgotPassword') }}
              </ButtonElement>
            </div>
          </section>

          <ResponseAlert
            v-if="$store.getters.errorResponse.action == 'login'"
            :response="$store.getters.errorResponse.data"
            class="mt-3"
          ></ResponseAlert>

          <section class="login-button mt-3">
            <ButtonElement
              orange
              type="submit"
              :loading="$store.getters.loading"
              :disabled="invalid"
            >
              {{ $t('serviceProvider.logIn.logInButton') }}
            </ButtonElement>
          </section>
        </ValidationObserver>
        <section v-if="role === 'user'" class="social-login">
          <div class="delimiter my-3">
            <div class="hr"></div>
            <div class="text">or</div>
          </div>
          <div class="social-button mt-3">
            <a href="https://api.hoodty.devla.dev/api/v1/user/login/facebook">
              <ButtonElement color="#3455A8">
                <div class="button-content">
                  <span>
                    <FacebookIcon width="20" height="20" />
                  </span>
                  <span class="button-text" :style="{ color: 'white' }">
                    {{ $t('enduser.signUp.continueWithFacebook') }}
                  </span>
                </div>
              </ButtonElement>
            </a>
          </div>
          <div class="social-button mt-3">
            <a href="https://api.hoodty.devla.dev/api/v1/user/login/google">
              <ButtonElement orange-secondary>
                <div class="button-content">
                  <span>
                    <GoogleIcon />
                  </span>
                  <span class="button-text">
                    {{ $t('enduser.signUp.continueWithGoogle') }}
                  </span>
                </div>
              </ButtonElement>
            </a>
          </div>
        </section>
        <section v-if="role !== 'staff'" class="footnote">
          {{ $t('serviceProvider.logIn.dontHaveAnAccount') }}
          <ButtonElement
            :anchor="{
              name: role === 'admin' ? 'AdminRegister' : 'UserRegister',
            }"
            link
          >
            {{ $t('serviceProvider.logIn.signUpNow') }}
          </ButtonElement>
        </section>
      </main>
    </div>
  </div>
</template>

<script>
import { ref } from '@vue/composition-api';
import InputElement from '../components/FormControl/InputElement.vue';
import ButtonElement from '../components/FormControl/ButtonElement.vue';
import EyeIcon from '../components/Svgs/EyeIcon.vue';
import CheckboxElement from '../components/FormControl/CheckboxElement.vue';
import FacebookIcon from '../components/Svgs/Social/FacebookIcon.vue';
import GoogleIcon from '../components/Svgs/Social/GoogleIcon.vue';
import store from '../store';
import router from '../router';

export default {
  setup() {
    const email = ref('');
    const password = ref('');
    const passwordVisibility = ref(false);
    const rememberMe = ref(false);
    const loginForm = ref();

    const onSubmit = async role => {
      const valid = await loginForm.value.validate();
      if (!valid) return;

      if (role === 'admin') {
        await store.dispatch('loginCompanyUser', {
          email: email.value,
          password: password.value,
          validator: loginForm.value,
        });
      } else if (role === 'staff') {
        await store.dispatch('loginStaffUser', {
          email: email.value,
          password: password.value,
          validator: loginForm.value,
        });
      } else {
        await store.dispatch('loginEndUser', {
          email: email.value,
          password: password.value,
          validator: loginForm.value,
        });
      }

      router.push({ name: 'Dashboard' });
    };

    return {
      email,
      password,
      passwordVisibility,
      rememberMe,
      onSubmit,
      loginForm,
    };
  },
  components: {
    GoogleIcon,
    FacebookIcon,
    EyeIcon,
    ButtonElement,
    InputElement,
    CheckboxElement,
  },
  props: {
    role: {
      type: String,
      required: true,
    },
  },
};
</script>

<style lang="scss" scoped>
.login {
  padding: 20px;
  background-position: center;
  background-size: cover;
  height: 100vh;
  overflow-y: auto;

  &.admin {
    background-image: linear-gradient(rgba(0, 0, 0, 0.4), rgba(0, 0, 0, 0.4)),
      url('../assets/pics/pexels-burst-374006.jpg');
  }
  &.user {
    background-image: linear-gradient(rgba(0, 0, 0, 0.4), rgba(0, 0, 0, 0.4)),
      url('../assets/pics/pexels-fauxels-3184634.png');
  }
}
.header {
  position: fixed;
  top: 20px;
  left: 20px;
}

.login-box {
  background-color: white;
  border-radius: 10px;
  max-width: 460px;
  margin: 150px auto 0;
  padding: 30px;
}
.title {
  color: $hty-orange;
  font-style: normal;
  font-weight: 500;
  font-size: 2rem;
  line-height: 1.2;
  display: flex;
  justify-content: center;
}
.action {
  font-style: normal;
  font-weight: 500;
  font-size: 1.1rem;
  line-height: 1.2;
  color: $hty-orange;
  margin-top: $hty-margin-hi;
  display: flex;
  justify-content: flex-end;
}
.form-control-custom {
  margin-top: 15px;

  > :last-child {
    margin-top: $hty-margin-low;
  }
}
.input-password {
  position: relative;
}
.password {
  padding-right: 32px;
}
.eye-icon {
  position: absolute;
  right: 10px;
  top: 12px;
}
.password-actions {
  display: flex;
  justify-content: space-between;
  margin-top: 1rem;
}
.login-button {
  ::v-deep .button {
    width: 100%;
  }
}
.social-button {
  ::v-deep .button {
    width: 100%;
  }
}
.button-content {
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;

  > :first-child {
    // position: absolute;
    // left: 0;
    // top: 0;
    display: flex;
    margin-right: 0.5rem;
  }

  > .button-text {
    font-style: normal;
    font-weight: 500;
    font-size: 1rem;
    line-height: 1.2;
  }
}
.footnote {
  margin-top: $hty-margin-low;
  text-align: center;
}
.remember-me {
  display: flex;
  > :first-child {
    margin-right: 5px;
  }
  > :last-child {
    font-style: normal;
    font-weight: 500;
    font-size: 0.9rem;
    line-height: 1.5;

    color: #999999;
  }
}
.delimiter {
  height: 0;

  > .hr {
    width: 100%;
    border-top: 1px solid #bdbdbd;
  }

  > .text {
    position: relative;
    bottom: 10px;
    left: 50%;
    transform: translateX(-50%);
    width: 40px;
    background-color: white;
    text-align: center;
  }
}
</style>

<template>
  <svg
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M4.43237 12.086L3.73621 14.6848L1.19175 14.7387C0.431324 13.3283 0 11.7146 0 9.99974C0 8.34151 0.403277 6.77778 1.11811 5.40088H1.11866L3.38395 5.81619L4.37628 8.06788C4.16859 8.67338 4.05539 9.32338 4.05539 9.99974C4.05546 10.7338 4.18843 11.4371 4.43237 12.086Z"
      fill="#FBBB00"
    />
    <path
      d="M19.8251 8.13184C19.9399 8.73675 19.9998 9.36147 19.9998 9.99994C19.9998 10.7159 19.9245 11.4142 19.7811 12.0878C19.2943 14.3801 18.0223 16.3817 16.2603 17.7982L16.2597 17.7977L13.4065 17.6521L13.0027 15.1312C14.1719 14.4455 15.0856 13.3724 15.5669 12.0878H10.2197V8.13184H15.6449H19.8251Z"
      fill="#518EF8"
    />
    <path
      d="M16.2599 17.7979L16.2604 17.7984C14.5467 19.1759 12.3698 20.0001 10 20.0001C6.19184 20.0001 2.8809 17.8715 1.19189 14.7391L4.43252 12.0864C5.27701 14.3402 7.45116 15.9446 10 15.9446C11.0956 15.9446 12.122 15.6485 13.0027 15.1314L16.2599 17.7979Z"
      fill="#28B446"
    />
    <path
      d="M16.3828 2.30216L13.1433 4.95432C12.2318 4.38456 11.1543 4.05543 9.99994 4.05543C7.39337 4.05543 5.17855 5.73342 4.37637 8.06804L1.11871 5.40104H1.11816C2.78244 2.19228 6.13514 0 9.99994 0C12.4263 0 14.651 0.864288 16.3828 2.30216Z"
      fill="#F14336"
    />
  </svg>
</template>

<script>
export default {};
</script>

<style lang="scss" scoped></style>

<template>
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M20.7657 0H3.23405C1.44797 0 0 1.44791 0 3.23405V20.7658C0 22.552 1.44791 23.9999 3.23405 23.9999H11.8806L11.8953 15.4236H9.66722C9.37766 15.4236 9.14264 15.1895 9.14153 14.8999L9.13084 12.1354C9.12973 11.8443 9.36545 11.6077 9.65659 11.6077H11.8807V8.93651C11.8807 5.83661 13.7739 4.14869 16.5392 4.14869H18.8083C19.0987 4.14869 19.3341 4.38406 19.3341 4.67444V7.00547C19.3341 7.29573 19.0988 7.53104 18.8086 7.53122L17.4161 7.53187C15.9122 7.53187 15.621 8.24648 15.621 9.29522V11.6078H18.9255C19.2404 11.6078 19.4847 11.8827 19.4476 12.1954L19.1199 14.9599C19.0886 15.2244 18.8642 15.4237 18.5979 15.4237H15.6358L15.621 24H20.7658C22.5519 24 23.9998 22.5521 23.9998 20.766V3.23405C23.9998 1.44791 22.5519 0 20.7657 0Z"
      :fill="color"
    />
  </svg>
</template>

<script>
export default {
  props: {
    color: {
      type: String,
      default: 'white',
    },
  },
};
</script>

<style lang="scss" scoped></style>

<template>
  <svg
    width="22"
    height="22"
    viewBox="0 0 22 22"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M12.7666 12.7666C12.5377 13.0122 12.2617 13.2092 11.955 13.3459C11.6484 13.4825 11.3173 13.556 10.9817 13.5619C10.646 13.5678 10.3126 13.5061 10.0013 13.3803C9.68997 13.2546 9.40719 13.0675 9.16979 12.8301C8.93239 12.5927 8.74525 12.3099 8.61951 11.9986C8.49377 11.6873 8.43202 11.3539 8.43795 11.0182C8.44387 10.6825 8.51734 10.3515 8.65398 10.0448C8.79062 9.73815 8.98763 9.46215 9.23325 9.23327M15.9499 15.9499C14.5254 17.0358 12.7908 17.6373 10.9999 17.6666C5.16658 17.6666 1.83325 10.9999 1.83325 10.9999C2.86983 9.06819 4.30753 7.38045 6.04992 6.04994L15.9499 15.9499ZM9.24992 4.53327C9.82353 4.39901 10.4108 4.33189 10.9999 4.33327C16.8333 4.33327 20.1666 10.9999 20.1666 10.9999C19.6607 11.9463 19.0575 12.8372 18.3666 13.6583L9.24992 4.53327Z"
      :stroke="color"
      stroke-width="1.5"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M1.83325 1.83337L20.1666 20.1667"
      :stroke="color"
      stroke-width="1.75"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
  </svg>
</template>

<script>
export default {
  props: {
    color: {
      type: String,
      default: '#737373',
    },
  },
};
</script>

<style lang="scss" scoped></style>
